import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';
import { StyledAvatar } from './style';

const Avatar = ({ src, color, width, alt, ...props }) => (
  <Tooltip title={alt || ''}>
    <StyledAvatar src={src} color={color} width={width} {...props} />
  </Tooltip>
);

Avatar.propTypes = {
  width: PropTypes.string,
  color: PropTypes.string,
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  alt: PropTypes.string,
};

Avatar.defaultProps = {
  width: '60px',
  color: undefined,
  src: undefined,
  alt: '',
};

export default Avatar;
