/* eslint-disable no-unused-vars */
import { useQuery } from '@apollo/client';
import { UPGRADE_STATUS } from '@app/apollo/queries';
import { Router, useTranslation } from '@app/config/i18n';
import { isOrganiser, redirectToRoute, setAuthCookie } from '@app/helpers';
import { useUser } from '@app/helpers/hooks';
import Link from '@atoms/Link';
import { Button, IconButton, MenuItem, Toolbar, Menu } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LocaleSelect from '@molecules/LocaleSelect';
import Image from 'next/image';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Cookies from 'js-cookie';
import { AppBar, LogoContainer, StyledMenu, UserAvatar, UserButton } from './style';

// import CookieAlert from '@molecules/common/CookieAlert';
// import Button from '@atoms/Button';



const Header = ({ sidebar, onClick, hasElevation }) => {
  const [auth, setAuth] = useState(false);
  const [logo, setLogo] = React.useState();
  // const [showCookieAlert, setShowCookieAlert] = useState(false);
  const [isMobile, setIsMobile]= useState(false)
  const [anchorEl, setAnchorEl] = useState(null);
  const { t, i18n } = useTranslation(['common', 'applications']);
  const { user } = useUser();

  const { data: { upgradeStatus } = {}, loading } = useQuery(UPGRADE_STATUS);

  const open = Boolean(anchorEl);

React.useEffect(() => {
  if (window.innerWidth < 600) {
    setIsMobile(true)
  //  setLogo();
  }
}, []);

//  console.log(upgradeStatus)

  if (!auth && user) {
    setAuth(true);
  }

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const redirectToProfile = () => {
    redirectToRoute({}, 'update_profile');
  };

  const redirectToUpgradeAcount = () => {
    // let route = 'my_opportunities';
    // if (auth && user.role.name === USER_ROLES.USER) {
    //   route = 'my_applications';
    // }
   redirectToRoute({}, 'account_upgrade');
  };

  const signOutHandler = () => {

    setAuthCookie(null);
    Cookies.remove('token');
    Router.reload();

  };


  const getHomeHref = () => {

    let route = '/';
    if (auth && user) {
      if (user.selected_type && user.selected_type === "organiser") {

        route = 'my_opportunities';
      } else {

        route =  'opportunity_search';
       ;
      }
    }
    return route;
  };




  return (
    <>
      {/* <NoSsr>{showCookieAlert && <CookieAlert closePopup={closePopupHandler} />}</NoSsr> */}
      <AppBar position="fixed" haselevation={hasElevation ? 1 : 0}>
        <Toolbar>
          {sidebar && (
            <IconButton color="inherit" aria-label="open drawer" onClick={onClick} edge="start">
              <MenuIcon />
            </IconButton>
          )}
          <LogoContainer>
            <Link
              href={getHomeHref()}
              color="inherit"
              title="home page"
              style={{ display: 'flex' }}
            >
              {isMobile ? (
                <Image
                  bottom="0.3125rem"
                  width="50px"
                  height="50px"
                  src="/images/logo-mobile.png"
                  alt="Mubazar"
                />
              ) : (
                <Image
                  bottom="0.3125rem"
                  width="143px"
                  height="30px"
                  src="/images/mubazar-logo-final-white.png"
                  alt="Mubazar"
                />
              )}
              {/* <Image bottom="0.3125rem" width="143px" src={Logo}  /> */}
              {/* <Typography variant="h6">mubazar</Typography> */}
            </Link>
            {/* <SearchIconBox>
              {' '}
              <SearchIcon fontSize="small"/>
            </SearchIconBox> */}
          </LogoContainer>
          <>
            <LocaleSelect id="header-language-menu" />
            {auth && user ? (
              <div>
                {/* <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  {data.me.avatar ? (
                    <Avatar width="40px" src={data.me.avatar.thumb} />
                  ) : (
                    <AccountCircle />
                  )}

                </IconButton> */}

                <UserButton
                  // variant="outlined"
                  variant="text"
                  color="inherit"
                  onClick={handleMenu}
                >
                  {' '}
                  <UserAvatar color="primary" width="40px" src={user.avatar.thumb} /> {user.name}{' '}
                </UserButton>

                <StyledMenu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  open={open}
                  onClose={handleClose}
                >
                  <MenuItem onClick={redirectToProfile}>{t('common:profile')}</MenuItem>

                  <MenuItem onClick={signOutHandler}>
                    <Link href="/" style={{ textDecoration: 'none' }} color="inherit">
                      {t('logout')}
                    </Link>
                  </MenuItem>
                  {!isOrganiser(user) && (
                    <MenuItem
                      style={{ borderTop: '1px solid lightgray' }}
                      disabled={
                        (upgradeStatus && upgradeStatus.status === 'waiting') ||
                        upgradeStatus === 'organizer'
                      }
                      onClick={redirectToUpgradeAcount}
                    >
                      {t('common:upgrade_account')}
                    </MenuItem>
                  )}
                </StyledMenu>
              </div>
            ) : (
              <>
                <Link href="login" id="login" color="inherit">
                  <Button color="inherit">{t('login')}</Button>
                </Link>

                <Link href="register" id="register" color="inherit">
                  <Button color="inherit">{t('register')}</Button>
                </Link>
              </>
            )}
          </>
        </Toolbar>
      </AppBar>
    </>
  );
};

Header.propTypes = {
  sidebar: PropTypes.bool,
  onClick: PropTypes.func,
  hasElevation: PropTypes.bool,
};

Header.defaultProps = {
  sidebar: false,
  hasElevation: true,
  onClick: () => {},
};


export default Header;
