export const deviceSizes = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  sm: '600px',
  tablet: '768px',
  md: '960px',
  laptop: '1024px',
  laptopMd: '1198px',
  lg: '1280px',
  laptopL: '1440px',
  xl: '1920px',
  desktop: '2560px',
};

//   export const devices = {
//     xs: `(max-width: ${deviceSizes.sm})`,
//     mobileS: `(min-width: ${deviceSizes.mobileS})`,
//     mobileM: `(min-width: ${deviceSizes.mobileM})`,
//     mobileL: `(min-width: ${deviceSizes.mobileL})`,
//     sm: `(min-width: ${deviceSizes.sm}) and (max-width: ${deviceSizes.md})`,
//     tablet: `(min-width: ${deviceSizes.tablet})`,
//     md: `(min-width: ${deviceSizes.md}) and (max-width: ${deviceSizes.lg})`,
//     laptop: `(min-width: ${deviceSizes.laptop})`,
//     lg: `(min-width: ${deviceSizes.lg}) and (max-width: ${deviceSizes.xl})`,
//     laptopL: `(min-width: ${deviceSizes.laptopL})`,
//     xl: `(min-width: ${deviceSizes.xl})`,
//     desktop: `(min-width: ${deviceSizes.desktop})`,
//     desktopL: `(min-width: ${deviceSizes.desktop})`
//   };

export const devices = {
  xs: `(max-width: ${deviceSizes.sm})`,
  sm: ` (max-width: ${deviceSizes.md})`,
  md: ` (max-width: ${deviceSizes.lg})`,
  lg: ` (max-width: ${deviceSizes.xl})`,
  mobileS: `(min-width: ${deviceSizes.mobileS})`,
  mobileM: `(min-width: ${deviceSizes.mobileM})`,
  mobileL: `(min-width: ${deviceSizes.mobileL})`,
  tablet: `(min-width: ${deviceSizes.tablet})`,
  laptop: `(min-width: ${deviceSizes.laptop})`,
  laptopL: `(min-width: ${deviceSizes.laptopL})`,
  xl: `(min-width: ${deviceSizes.xl})`,
  desktop: `(min-width: ${deviceSizes.desktop})`,
  desktopL: `(min-width: ${deviceSizes.desktop})`,
};
