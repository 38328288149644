import styled from 'styled-components';
import { AppBar as MuAppBar, Box, Menu } from '@mui/material';
import Button from '../../../atoms/Button';
import Avatar from '../../../atoms/Avatar';

export const LogoContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

export const SearchIconBox = styled(Box)`
  && {
    background-color: ${({ theme }) => theme.red[300]};
    margin-left: 20px;
    padding: 10px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const AppBar = styled(MuAppBar)`
  && {
    ${({ haselevation }) => !haselevation && `box-shadow: none;`}
  }

  a {
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
`;

export const UserButton = styled(Button)`
  && {
    border-radius: 25px;
    padding-left: 5px;
    padding-right: 10px;
    background-color: ${({ theme }) => theme && theme.red[400]};
  }
`;

export const UserAvatar = styled(Avatar)`
  && {
    padding: 0;
    margin-right: 10px;
  }
`;

export const StyledMenu = styled(Menu)`
  && {
    // .MuiMenu-paper {
    //   width:190px;
    // }
    top: 45px !important;
    left: 0 !important;
    right: 20px !important;
    
  }
`;
