import React from 'react';
import PropTypes from 'prop-types';
import { Button as MuiButton, CircularProgress } from '@mui/material';
import Link from '../Link';

const Button = ({ children, variant, color, loading, asLink, ...props }) => {
  if (asLink ) {
    return <MuiButton
      variant={variant === 'text' ? undefined : variant}
      color={color}
      component={Link}
      naked
      {...props}
    >
      {children}
    </MuiButton>
  }
  return (
    <MuiButton
    variant={variant === 'text' ? undefined : variant}
    color={color}
    disabled={loading}
    startIcon={loading && <CircularProgress size={20}/>}
    {...props}
  >
    {children}
  </MuiButton>
  )
}



Button.propTypes = {
  variant: PropTypes.string,
  color: PropTypes.string,
  loading: PropTypes.bool,
  children: PropTypes.any,
  asLink: PropTypes.bool
};

Button.defaultProps = {
  variant: 'contained',
  loading: false,
  asLink: false,
  color: undefined,
  children: '',
};

export default Button;
